<script>
import ListView from '@/components/ListView'
import { mapActions, mapGetters } from 'vuex'
import Moment from 'moment'
import OccurrencesItems from '@/enums/occurrences'

export default {
  extends: ListView,
  name: 'ViewListOccurrences',
  data(vm) {
    return {
      namePage: this.$i18n.t('monitor'),
      path: 'occurrences',
      filterContent: () => import('./OccurrencesFiltersComponent.vue'),
      headerContent: () => import('./OccurrencesHeaderComponent.vue'),
      hideDefaultActions: true,
      addNew: false,
      enablePrinting: true,
      dataProperties: { path: 'occurrences', namePage: this.$i18n.t('monitor') },
      headers: [
        {
          text: this.$i18n.t('occurrenceDate'),
          align: 'start',
          sortable: true,
          value: 'date',
          createFilter: true,
          filterType: 'date',
          component: 'DataTableTransformValueColumn',
          options: {
            transform(value) {
              return value ? Moment(value, 'DD/MM/YYYY').format('DD/MM/YYYY') : '-'
            },
          },
        },
        {
          text: this.$i18n.t('occurrenceTime'),
          align: 'start',
          value: 'date',
          createFilter: true,
          filterOptions: {
            getOptions(data) {
              data = data.map((el) => el.date).sort()
              return data.map((value) => ({
                text: Moment(value, 'DD/MM/YYYYHH:mm:ss').format('HH:mm'),
                value,
              }))
            },
          },
          component: 'DataTableTransformValueColumn',
          options: {
            transform(value) {
              return value ? Moment(value, 'DD/MM/YYYYHH:mm:ss').format('HH:mm') : '-'
            },
          },
        },
        {
          text: this.$i18n.t('transportationCode'),
          align: 'start',
          sortable: true,
          value: 'transportationCode',
          createFilter: true,
          width: '20%',
        },
        {
          text: this.$i18n.t('invoice'),
          align: 'start',
          sortable: true,
          value: 'invoiceCode',
          createFilter: true,
        },
        {
          text: this.$i18n.t('reason'),
          align: 'start',
          sortable: false,
          value: 'statusCode',
          createFilter: true,
          component: 'DataTableTransformValueColumn',
          filterOptions: {
            getOptions(data) {
              data = data.map((el) => el.statusCode).sort()
              return data.map((el) => ({
                text: OccurrencesItems.find((occurrence) => occurrence.value == Number(el)).valueText,
                value: el,
              }))
            },
          },
          options: {
            transform(value) {
              return OccurrencesItems.find((el) => el.value == Number(value)).valueText
            },
          },
        },
        {
          text: this.$i18n.t('processed'),
          align: 'start',
          sortable: false,
          value: 'type',
          component: 'YesNoChipStatus',
          createFilter: true,
          filterOptions: {
            getOptions(data) {
              data = data.map((el) => el.type).sort()
              return data.map((el) => ({
                text: el == 'S' ? vm.$i18n.t('yes') : vm.$i18n.t('no'),
                value: el,
              }))
            },
          },
          width: '8%',
          options: {
            transform(value) {
              return value == 'S' ? true : false
            },
          },
        },
        {
          text: this.$i18n.t('message'),
          align: 'start',
          sortable: false,
          value: 'message',
        },
        {
          text: this.$i18n.t('logDate'),
          align: 'start',
          sortable: false,
          createFilter: true,
          filterType: 'date',
          value: 'createdDate',
          component: 'DataTableTransformValueColumn',
          options: {
            transform(value) {
              return value ? Moment(value, 'DD/MM/YYYY').format('DD/MM/YYYY') : '-'
            },
          },
        },
        {
          text: this.$i18n.t('logTime'),
          align: 'start',
          sortable: false,
          value: 'createdHour',
          createFilter: true,
          component: 'DataTableTransformValueColumn',
          options: {
            transform(value) {
              return value ? Moment(value, 'HH:mm:ss').format('HH:mm') : '-'
            },
          },
          filterOptions: {
            getOptions(data) {
              data = data.map((el) => Moment(el.createdHour, 'HH:mm:ss').format('HH:mm')).sort()
              return data
            },
          },
        },
        {
          text: this.$i18n.t('estimatedDate'),
          align: 'start',
          sortable: true,
          createFilter: true,
          filterType: 'date',
          value: 'estimatedDate',
        },
        {
          text: this.$i18n.t('updatedDate'),
          align: 'start',
          sortable: true,
          createFilter: true,
          filterType: 'date',
          value: 'updatedDate',
        },
        {
          text: this.$i18n.t('sapCode'),
          align: 'start',
          sortable: true,
          createFilter: true,
          value: 'sapCode',
        },
        {
          text: this.$i18n.t('transportCompanyCode'),
          align: 'start',
          sortable: true,
          createFilter: true,
          value: 'transportCompanyCode',
        },
      ],
    }
  },
  computed: {
    ...mapGetters('occurrencesStore', {
      getGoodList: 'getList',
    }),
  },
  methods: {
    ...mapActions('occurrencesStore', ['setDataToList', 'setDefaultPath']),
    async getItemsList() {
      return true
    },
  },
}
</script>
